import $merge from 'lodash.merge'
import { Howler, Howl } from 'howler'

import MediaPlayer from '../../services/MediaPlayer'
import Realtime from '../../services/Realtime'
import { CoreError } from '../../utils/error'
import warn from '../../utils/warning'
import CommonMedia from './Media'

/**
 * @class Audios
 * @extends Model
 * @description
 * Audio model
 *
 * @todo
 * implement play method
 * User:play -> Server:authentify & generate tmp token -> User:fetch media with token
 *
 * @todo
 * should be commented (good first commit)
 */
const DEFAULT_MODEL = {
  value: '',
  type: 'audio',
  metadatas: {
    duration: 0,
    position: 0,
    title: 'Unknown',
  },
}

// note(dev):
// `play` must be omitted here
// there is some case where howler
// will call `play` multiple times
// a trick is made in `load` to prevent
// multiple invocations
const HOWLER_EVENTS = [
  'load',
  'loaderror',
  'playerror',
  'end',
  'pause',
  'stop',
  'mute',
  'volume',
  'rate',
  'seek',
  'fade',
  'unlock',
]

export default class CommonAudio extends CommonMedia {
  static modelName = 'Audio'
  static modelDefaults = DEFAULT_MODEL

  /**
   * @api private
   * @description
   * attached content (yes, it's a circular reference)
   */
  #content = null

  /**
   * @api private
   * @description
   * episode index (array  index in the parent content audios metadatas)
   */
  #episodeIndex = 0

  /**
   * @api private
   * @see load method
   * @see Howler
   */
  #player = null

  /**
   * @api public
   * @description
   * status of the current player
   * can have the following values
   * - unload
   * - loading
   * - load
   * - error
   * - pause
   * - play
   * - stop
   * default value is `unload`
   */
  status = 'unload'

  /**
   * @api public
   * @description
   * computed when audio file is loaded (see event load)
   * and used to compute the progression percentage of
   * read when the media's playing
   */
  computedAudioDuration = 0

  /**
   * @api public
   * @description
   * mutex set to `true` if an audio file has been launched
   * this mutex is never reset, so, if a media is played and
   * the reading is finished, this mutex is always `true`
   */
  hasBeenLaunched = false

  /**
   * @api public
   * @description
   * mutex set to `true` if a media is launched and has it
   * current time greater than 0
   * this mutex is reset when the media is stoped or ended
   */
  isLaunched = false

  constructor(data, content, index) {
    super($merge({}, DEFAULT_MODEL, data))

    this.#content = content
    this.#episodeIndex = index
  }

  static resource = 'audios'

  get content() {
    return this.#content
  }

  get currentTime() {
    return this.#player ? this.#player.seek() : 0
  }

  get duration() {
    return this.$metadata('duration', 0)
  }

  get progress() {
    let time = 0
    let percent = 0

    if (this.#player) {
      time = this.currentTime
      percent = time / this.computedAudioDuration
    }

    return {
      time,
      percent,
    }
  }

  get isError() {
    return ['error', 'loaderror', 'playerror'].includes(this.status)
  }

  get isLoading() {
    return this.status === 'loading'
  }

  get isLoaded() {
    return (
      ['loading', 'error', 'loaderror', 'unload'].includes(this.status) ===
      false
    )
  }

  get isPaused() {
    return this.status === 'pause'
  }

  get isPlaying() {
    return this.status === 'play'
  }

  get isStopped() {
    return this.status === 'stop'
  }

  get isUnload() {
    return this.status === 'unload'
  }

  get player() {
    return this.#player
  }

  set volume(value) {
    if (this.player) {
      this.player.volume(value)
    }
  }

  async play(startPosition = null) {
    if (!this.#player) {
      this.load()
    }

    this.volume = MediaPlayer.volume

    if (this.isPlaying) {
      return this
    }

    if (startPosition) {
      this.#player.seek(time)
    }

    if (MediaPlayer.isContent(this.#content, this.#episodeIndex) === false) {
      if (this.isPlaying === true) {
        MediaPlayer.stop()
      }
      MediaPlayer.setContent(this.#content, this.#episodeIndex)
    }

    try {
      if (Howler.ctx && Howler.ctx.state == 'suspended') {
        try {
          await Howler.ctx.resume()
        } catch (error) {
          if (!!window.AudioContext) {
            Howler.ctx = new AudioContext()
            await Howler.ctx.resume()
          }
        }
      }

      this.#player.play()
    } catch (error) {
      this.status = 'error'
      throw new CoreError('unable to play audio source')
    }

    return this
  }

  load(options) {
    if (this.#player || this.status === 'loading') {
      return this
    }

    const propagateEvent = (eventName, context) => (data) =>
      context.emit(eventName, data)
    const dispatchEvent = (eventName) => (data) => {
      propagateEvent(eventName, this)(data)
      propagateEvent(eventName, MediaPlayer)({ content: this.#content, event })
    }

    try {
      this.status = 'loading'

      const player = new Howl({
        src: this.$data('value'),
        html5: true,
        preload: true,
        ...options,
      })

      this.#player = player
      this.#player.episode = this

      // trick to display track title when mobile is locked
      if (this.#player && this.#player._sounds && this.#player._sounds.length) {
        this.#player._sounds.forEach((sound) => {
          if (sound._node) {
            sound._node.setAttribute(
              'title',
              `${this.$metadata('title')} - ${this.#content.title}`
            )
          }
        })
      }

      HOWLER_EVENTS.forEach((eventName) => {
        this.#player.on(eventName, dispatchEvent(eventName))
      })

      this.#player.once('load', () => {
        this.computedAudioDuration = this.#player.duration()
      })

      let rafCtx

      const computeAndDispatchCurrentProgression = () => {
        const progress = this.progress

        propagateEvent('timeupdate', this)(progress)
        propagateEvent('timeupdate', MediaPlayer)(progress)
      }

      this.#player.on('play', () => {
        if (this.status !== 'play') {
          dispatchEvent('play')()
          this.status = 'play'
          this.hasBeenLaunched = true
          this.isLaunched = true
          Realtime.publish('track', {
            media: {
              id: this.id,
              status: 'play',
              startPosition: this.currentTime * 1000,
            },
          })

          rafCtx = setInterval(
            computeAndDispatchCurrentProgression,
            MediaPlayer.timeUpdateInterval
          )
        }
      })

      // we unload the state of the webaudio component
      // to prevent external causes of issues (network...)
      // and allow client to manually retry
      this.#player.on('loaderror', (error) => {
        this.status = 'error'
        this.unload()
      })

      this.#player.on('end', () => {
        this.stop()
      })

      this.#player.on('pause', () => {
        this.status = 'pause'
        Realtime.publish('track', { media: { status: 'stop' } })
        clearInterval(rafCtx)
      })

      this.#player.on('seek', () => {
        computeAndDispatchCurrentProgression()
      })

      this.#player.on('stop', () => {
        this.isLaunched = false
        this.status = 'stop'

        clearInterval(rafCtx)
        Realtime.publish('track', { media: { status: 'stop' } })
        this.unload()
      })
    } catch (error) {
      this.emit('loaderror', error)
    }

    return this
  }

  unload() {
    if (this.#player) {
      this.status = 'unload'
      this.#player &&
        this.#player._state !== 'unloaded' &&
        this.#player.unload()
      this.#player = null
    }

    return this
  }

  pause() {
    if (this.#player) {
      this.status = 'pause'
      this.#player.pause()

      // if content is of type 'live'
      // stream must be STOPPED (and not paused)
      // the trick here:
      const mustBeUnloaded =
        this.content.$data('type') === 'live' &&
        (this.isError || this.isPlaying)

      if (mustBeUnloaded) {
        setTimeout(() => {
          this.unload()
        }, 0)
      }
    }

    return this
  }

  seek(time) {
    setTimeout(() => {
      if (this.#player) {
        this.#player.seek(time)
      }
    }, 20)

    return this
  }

  stop() {
    if (this.#player) {
      this.status = 'stop'
      this.#player.stop()
    }

    return this
  }
}
